<template>
  <section class="about-wrapper">
    <div class="vines"></div>
    <!-- ABOUT -->
    <div class="about-container">
      <div class="animated-text">
        <div class="container">
          <div class="text">I'm a</div>
          <ul>
            <li>traveller</li>
            <li class="animated-hide">cinema & video games fan</li>
            <li class="animated-show">geek</li>
            <li>rock climber</li>
            <li>bookworm</li>
          </ul>
        </div>
      </div>

      <table>
        <tr>
          <td width="45%" class="about-CV">
            <h2 class="wow fadeIn">
              I'm also a front end developer with an eye for design
            </h2>
            <a href="CV.pdf" target="_blank" class="cy-CV">
              <div class="wow button rubberBand" data-wow-delay="1s">
                Download CV
              </div>
            </a>

            <div class="couch-container"></div>
            <div class="wow blink light"></div>
            <a href="CV.pdf" target="_blank" class="cy-CV"
              ><div class="wow eye-blink totoro-eyes"></div
            ></a>
          </td>

          <td width="55%" class="about-description wow fadeIn">
            <b>About me:</b> Born in France, I have always had a keen interest
            in design and new technologies. My academic studies and work
            experiences have played a pivotal role in developing both my
            creativity and technical skills. Additionally, I am deeply
            passionate about travel and exploration, and I've had the privilege
            to work in countries boasting rich cultures and stunning landscapes,
            which have been a tremendous source of inspiration in my daily life.
            <br />
            <p>
              Currently, I am an Australian citizen residing in the Brisbane
              area. With a strong background in development and a sharp eye for
              design, I thoroughly enjoy taking on multiple tasks and am always
              eager to embrace new challenges. At this moment, my preferred
              technologies include VueJS and ReactJS.
            </p>
          </td>
        </tr>
      </table>
    </div>

    <div class="about-floor"></div>
  </section>
</template>

<script type="ts" setup></script>
