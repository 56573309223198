<template>
  <div class="loading-screen">
    <div class="loader"></div>
    Loading...
  </div>
</template>

<script>
export default {
  name: "LoadingScreen",
};
</script>